import React, { useContext, lazy, Suspense } from 'react';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import UserContext from './store/userContext';
import { Backdrop, CircularProgress } from '@mui/material';

const Login = lazy(() => import('./pages/auth/login'));
const CaseManagerProfile = lazy(() => import('./pages/caseManagerProfile/caseManagerProfile'));
const EditCaseManagerProfile = lazy(() => import('./pages/caseManagerProfile/editCaseManagerProfile'));
const Layout = lazy(() => import('./components/layout/layout'));
const ClientProfile = lazy(() => import('./pages/clientProfile/clientProfile'));
const ResetPassword = lazy(() => import('./pages/auth/resetPassword'));
const CaseManagers = lazy(() => import('./pages/list/caseManagers'));
const AddEditClientTransaction = lazy(() => import('./pages/transaction/addEditClientTransaction'));
const Transactions = lazy(() => import('./pages/list/transactions'));
const PayeeInfoDetails = lazy(() => import('./payeeInfoDetails'));
const PayeeStatus = lazy(() => import('./payeeStatus'));
const NotFoundPage = lazy(() => import('./pages/notFoundPage'));
const ViewCaseManagerProfile = lazy(() => import('./pages/caseManagerProfile/viewCaseManagerProfile'));
const AddEditClientProfile = lazy(() => import('./pages/clientProfile/addEditClientProfile'));


const App = () => {
    const {isLoggedIn} = useContext(UserContext);

    const router = createBrowserRouter([
        {
            path: '/login',
            element: !isLoggedIn ? <Login /> : <Navigate to="/" />,
        },
        {
            path: '/reset-password',
            element: <ResetPassword />,
        },
        {
            path: '/payee/:id',
            element: <PayeeInfoDetails />,
        },
        {
            path: '/payee/status',
            element: <PayeeStatus />,
        },
        {
            path: '/',
            element: isLoggedIn ? <Layout /> : <Navigate to="/login" />,
            children: [
                {
                    path: '',
                    element: <Navigate to="/profile" />,
                },
                {
                    path: 'profile',
                    element: <CaseManagerProfile />,
                },
                {
                    path: 'profile/edit',
                    element: <EditCaseManagerProfile />,
                },
                {
                    path: 'client',                   
                    children: [
                        {
                            path: '',
                            element: <ClientProfile />,
                        },
                        {
                            path: 'add',
                            element: <AddEditClientProfile />,
                        },
                        {
                            path: 'edit',
                            element: <AddEditClientProfile />,
                        },
                        {
                            path: 'view',
                            element: <AddEditClientProfile />,
                        }
                    ]
                },              
                {
                    path: 'casemanager/list',
                    element: <CaseManagers />,
                },
                {
                    path: 'casemanager/view',
                    element: <ViewCaseManagerProfile />,
                },
                {
                    path: 'transaction/list',
                    element: <Transactions />

                },
                {
                    path: 'transaction',
                    children: [
                        {
                            path: 'add',
                            element: <AddEditClientTransaction />,
                        },
                        {
                            path: 'edit',
                            element: <AddEditClientTransaction />,
                        },
                        {
                            path: 'view',
                            element: <AddEditClientTransaction />,
                        },
                    ]
                },
            ],
        },
        {
          path: "*",
          element: <NotFoundPage />,
        },
    ]);

    // return (
    //     <Suspense>
    //         <RouterProvider router={router} />
    //     </Suspense>
    // );

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <RouterProvider router={router} />
        </Suspense>
    );
};

export default App;
