import { Auth } from 'aws-amplify';

const signOutHandler = async () => {
    try {
        await Auth.signOut();
        localStorage.clear();
    } catch (error) {
        console.log('error signing out: ', error);
    }

    window.location.assign('/');
};

export default signOutHandler;
