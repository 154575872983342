import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import httpRequestHandler from '../utils/helpers/httpRequestHandler';
import signOutHandler from '../utils/helpers/signOutHandler';
 
const UserContext = React.createContext({
    userData: null,
    uiSecret: null,
    setUserDataHandler: (userData) => {},
});
 
export const UserContextProvider = (props) => {
    const [userData, setUserData] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [agencyPreference, setAgencyPreference] = useState(false);
    const [uiSecret, setUiSecret] = useState(null);
 
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(async (user) => {
                if (user) {
                    if (!user.attributes.email_verified) {
                        setUserData(null);
                        signOutHandler();
                    } else {
                        setIsLoggedIn(true);
                        const res = await httpRequestHandler('/getcasemanagerbyemailid', 'post', {
                            email: user.attributes.email,
                        });
                        setUserData(res[0]);
                    }
                    if(uiSecret == null)
                    {
                        const res1 = await httpRequestHandler('/getuisettings', 'get');
                        setUiSecret(res1);
                    }
                }
            })
            .catch((e) => {
                console.log(e);
                setIsLoggedIn(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);
 
    const setUserDataHandler = (userData) => {
        setUserData(userData);
    };
 
    const setAgencyPreferenceHandler = (value) => {
        setAgencyPreference(value);
    };
 
    const setUiSecretHandler = (uiSecret) => {
        setUiSecret(uiSecret);
    };
 
    const contextValue = {
        isLoggedIn: isLoggedIn,
        userData,
        agencyPreference,
        setAgencyPreferenceHandler,
        setUserDataHandler,
        uiSecret,
        setUiSecretHandler
    };
 
    if (isLoading) {
        return
    }
 
    return <UserContext.Provider value={contextValue}>{props.children}</UserContext.Provider>;
};
 
export default UserContext;
